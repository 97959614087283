import {formatVolume} from "../helpers";
import {useGlobalStore} from "../Stores/Store";
import {createPortal} from "react-dom";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import {overlayStates} from "../consts";
import {OverlayResume} from "./OverlayResume";
import {OverlayForm} from "./OverlayForm";

export function MobileOverlay() {
    const {totalVolume, showOverlay, setShowOverlay, overlayState, setOverlayState, hasItems} = useGlobalStore();

    const classNames = clsx({
        "meuselfstorage__calculadora__mobile-overlay": true,
        "meuselfstorage__calculadora__mobile-overlay--open": showOverlay,
    });

    React.useEffect(() => {
        if (showOverlay === false) {
            setOverlayState(overlayStates.none);
        }
    }, [showOverlay]);

    return createPortal(
        <div className={classNames}>
            <FontAwesomeIcon className="meuselfstorage__calculadora__mobile-overlay__chevron" icon={faChevronUp}
                             onClick={() => {
                                 if (!showOverlay) {
                                     setShowOverlay(true);
                                     setOverlayState(overlayStates.resume);
                                 } else {
                                     setShowOverlay(false);
                                 }
                             }}/>

            {(overlayState === overlayStates.none) && 
                <p className="meuselfstorage__calculadora__mobile-overlay__total">Você precisa
                    de <span>{formatVolume(totalVolume)}m³</span></p>
            }

            {showOverlay === false ?
                <button onClick={() => {
                    setOverlayState(overlayStates.form);
                    setShowOverlay(true);
                }}
                        disabled={!hasItems}
                >
                    Solicitar orçamento
                </button>
                : null
            }

            {overlayState === overlayStates.resume &&
                <div className="meuselfstorage__calculadora__mobile-overlay__content"><OverlayResume/></div>
            }

            {overlayState === overlayStates.form &&
                <div className="meuselfstorage__calculadora__mobile-overlay__content">
                    <OverlayForm/>
                </div>}
        </div>
        , document.body);
}