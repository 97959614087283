import {overlayStates} from "../consts";
import {formatVolume} from "../helpers";
import { useGlobalStore } from "../Stores/Store";

export function Header() {
    const { totalCount, totalVolume, setOverlayState, setShowOverlay } = useGlobalStore();

    return (
        <div className="meuselfstorage__calculadora__header">
            <div className="meuselfstorage__calculadora__header__left">
                <h2>Indique seus objetos para calcular o tamanho do box: </h2>
            </div>

            <div className="meuselfstorage__calculadora__header__right">
                <div className="meuselfstorage__calculadora__header__right__count">
                    <span>
                        {totalCount > 1 && `${totalCount} itens`}
                        {totalCount <= 1 && `${totalCount} item`}
                    </span>
                    <button onClick={() => {
                        setOverlayState(overlayStates.resume);
                        setShowOverlay(true);
                    }}>Ver itens
                    </button>
                </div>
                <div className="meuselfstorage__calculadora__header__right__volume">
                    <span>Você precisa de {formatVolume(totalVolume)}m³</span>
                    <button
                        onClick={() => {
                            setOverlayState(overlayStates.form);
                            setShowOverlay(true);
                        }}
                        className="meuselfstorage__calculadora__header__right__volume__btn-orcamento"
                    >Solicitar orçamento
                    </button>
                </div>
            </div>
        </div>
    );
}
