
export function useSession() {
    const STORAGE_KEY = 'calculadora_session';

    function getSelection() {
        const selection = window.sessionStorage.getItem(STORAGE_KEY);

        return selection ? JSON.parse(selection) : {};
    }

    function setSelection(selection: any) {
        window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selection));
    }

    function clearSession() {
        window.sessionStorage.clear();
    }

    return {
        getSelection,
        setSelection,
        clearSession,
    };
}
