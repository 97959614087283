import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

export function TabItem({ slug, currentTab, setTab, children }) {
    return (<li
        onClick={() => setTab(slug)}
        className={currentTab === slug ? 'meuselfstorage__tabs__tab-active' : ''}
    >
        <span>{children}</span>
        <FontAwesomeIcon icon={faChevronRight}/>
    </li>);
}
