import {SuccessImage} from "./Components/SuccessImage";

export function Success() {
    return (<div className="meuselfstorage__calculadora__success">
        <div className="meuselfstorage__calculadora__success__image-wrapper">
            <SuccessImage />
        </div>
        <h1>Recebemos sua solicitação</h1>
        <p>Em breve você receberá seu orçamento por e-mail e WhatsApp</p>
        <button onClick={() => window.location.assign('/')}>Ir para página inicial</button>
    </div>);
}
