import {overlayStates} from "../consts";
import {formatVolume} from "../helpers";
import {OverlayItemInput} from "../Components/OverlayItemInput";
import {useGlobalStore} from "../Stores/Store";

export function OverlayResume() {
    const { hasItems, totalVolume, selection, setSelection, setOverlayState } = useGlobalStore();

    return <>
        <h3>Confira seus itens</h3>
        <div className="meuselfstorage__calculadora__overlay__container__content">
            {!hasItems ? <>Não há ítens selecionados</> : null}
            <ul className="meuselfstorage__calculadora__overlay__container__content__list">
                {Object.values(selection).map((item) => {
                    if (item.qty === 0) return null;

                    return <li
                        key={`${item.id}_overlay`}
                        className="meuselfstorage__calculadora__overlay__container__content__list__item">
                        <div
                            className="meuselfstorage__calculadora__overlay__container__content__list__item__left">
                            <img src={item.image}/>
                        </div>
                        <div
                            className="meuselfstorage__calculadora__overlay__container__content__list__item__right">
                            <p>{item.name}</p>
                            <p className="meuselfstorage__calculadora__overlay__container__content__list__item__volume">
                                ~{formatVolume((item.volume * item.qty).toFixed(2))}m³
                            </p>
                        </div>
                        <OverlayItemInput item={item} />
                    </li>
                })}
            </ul>
        </div>
        {hasItems ?
            <div className="meuselfstorage__calculadora__overlay__container__content__review">
                <p>Você precisa de <span>{formatVolume(totalVolume)}m³</span></p>
                <button onClick={() => setOverlayState(overlayStates.form)}>
                    Finalizar e solicitar orçamento
                </button>
            </div>
            : null}
    </>
}