import {debounce} from "lodash-es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

export function SearchInput({ setFilter, defaultValue = "" }) {
    return (
        <div className="meuselfstorage__tabs__search">
            <input
                type="text"
                placeholder="Pesquise por um móvel"
                onChange={debounce((e) => {
                    setFilter(e.target.value);
                }, 250)}
                defaultValue={defaultValue}
            />
            <FontAwesomeIcon icon={faMagnifyingGlass}/>
        </div>
    )
}
