import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useMobile } from "../Hooks/useMobile";
import clsx from "clsx";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function debounce(fn: any) {
    let raf: any;
  
    return (...args: any) => {
      if (raf) {
        console.log('debounced');
        return;
      }
  
      raf = window.requestAnimationFrame(() => {
        fn(...args); // run useful code
        raf = undefined;
      });
    };
  }

export function GoToTop() {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleScroll = debounce(() => {
            if (window.scrollY > 300) {
                setVisible(() => true);
            }
            
            if (window.scrollY <= 300) {
                setVisible(() => false);
            }
        });

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    const classes = clsx({
        ["meuselfstorage__calculadora__go-to-top"]: true,
        ["meuselfstorage__calculadora__go-to-top--visible"]: visible,
    });

    function handleClick() {
        window.scrollTo({
            top: document.querySelector(".meuselfstorage__calculadora")?.scrollTop,
            behavior: "smooth", 
        });
    }

    return createPortal(
        <>
            <div className={classes} onClick={handleClick}>
                <FontAwesomeIcon className="meuselfstorage__calculadora__mobile-overlay__chevron" icon={faChevronUp} />
            </div>
        </>,
        document.body
    );
}
