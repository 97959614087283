
const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

export function formatVolume(number) {
    return formatter.format(number);
}
