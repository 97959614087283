import { useGlobalStore } from "../Stores/Store";
import { MobileTabItem } from "../Components/MobileTabItem";
import {SearchInput} from "../Components/SearchInput";

export function MobileHeader() {
    const { categories, filter, setTab, setFilter, currentTab } = useGlobalStore();

    return (<div className="meuselfstorage__calculadora__mobile-header">
        <h2>Indique seus objetos para calcular o tamanho do box:</h2>

        <div className="meuselfstorage__calculadora__mobile-header__categories">
            <ul>
                {categories.map((category) => (
                    <MobileTabItem key={category.id} slug={category.slug} setTab={setTab} currentTab={currentTab}>
                        {category.name}
                    </MobileTabItem>
                ))}

                <MobileTabItem
                    slug="suas_medidas"
                    currentTab={currentTab}
                    setTab={setTab}
                >Suas medidas</MobileTabItem>
            </ul>
        </div>
        <SearchInput setFilter={setFilter} defaultValue={filter} />
    </div>)
}
