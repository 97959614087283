import { create } from "zustand";
import { useSession } from "../Hooks/useSession";

export const useGlobalStore = create((set) => ({
    currentTab: "",
    medidas: {},
    selection: useSession().getSelection(),
    totalVolume: 0,
    totalCount: 0,
    showOverlay: false,
    overlayState: "",
    showSuccess: false,
    filter: "",
    hasItems: Object.keys(useSession().getSelection()).length > 0,
    categories: [],
    originalCategories: [],
    filiais: {},

    setSelection: (id, value) => set((state) => {
        const newSelection = { ...state.selection, ...value };
        const hasItems = Object.values(newSelection).filter((item) => item.qty > 0).length;
        useSession().setSelection(newSelection);

        return { selection: newSelection, hasItems };
    }),

    mutateSelection: (item, decrement = false, callback = (qty: number) => {}) => set((state) => {
        if (decrement) {
            if (!state.selection[item.id] || (state.selection[item.id] && state.selection[item.id].qty === 0)) {
                return state;
            }
        }

        const newSelection = {...state.selection};

        if (!newSelection[item.id]) {
            newSelection[item.id] = {
                ...item,
                qty: decrement ? 0 : 1,
            }
        } else if (decrement && newSelection[item.id] && newSelection[item.id].qty > 0) {
            newSelection[item.id].qty--;
        } else if (!decrement) {
            newSelection[item.id].qty++;
        }

        const hasItems = Object.values(newSelection).filter((item) => item.qty > 0).length;

        callback(newSelection[item.id].qty);
        useSession().setSelection(newSelection);

        return {
            selection: newSelection,
            hasItems,
        };
    }),

    setMedidas: (value) => set((state) => ({
        medidas: { ...state.medidas, ...value }
    })),

    setTotalVolume: (value) => set(() => ({ totalVolume: value })),
    setTotalCount: (value) => set(() => ({ totalCount: value })),
    setShowOverlay: (value) => set(() => ({ showOverlay: value })),
    setOverlayState: (value) => set(() => ({ overlayState: value })),
    setShowSuccess: (value) => set(() => ({ showSuccess: value })),
    setFilter: (value) => set(() => ({ filter: value })),
    setTab: (value) => set(() => ({ currentTab: value })),

    setInitialCategories: (value) => set(() => ({ categories: value, originalCategories: value })),
    setCategories: (value) => set(() => ({ categories: value })),
    setFiliais: (value) => set(() => ({ filiais: value })),
}));
