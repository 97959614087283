import {useEffect, useState} from "react";

export function useMobile() {
    const [isMobile, setMobile] = useState(() => checkViewport());
    let timeout;

    function checkViewport() {
        return window.matchMedia('screen and (max-width: 960px)').matches;
    }

    function resizeHandler() {
        if (timeout) {
            window.cancelAnimationFrame(timeout);
        }

        timeout = window.requestAnimationFrame(function () {
            setMobile(checkViewport());
        });
    }

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);

        return () => window.removeEventListener("resize", resizeHandler);
    }, []);

    return { isMobile };
}
