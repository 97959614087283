import {useEffect, useRef, useState} from "react";
import {uniqueId} from "lodash-es";
import {formatVolume} from "../helpers";

const defaultValue = {
    altura: 0,
    largura: 0,
    profundidade: 0,
    soma: 0,
};

export function SuasMedidas({ onChange, position = 0, initialValue = defaultValue }) {
    const [values, setValues] = useState(initialValue);
    const [init, setInit] = useState(true);
    const alturaRef = useRef();
    const larguraRef = useRef();
    const profundidadeRef = useRef();

    function handleChange(e) {
        init && setInit(false);

        setValues((prevState) => {
            const newState = { ...prevState, [e.target.name]: parseInt(e.target.value) };
            const soma = (newState.altura / 100)
                * (newState.largura / 100)
                * (newState.profundidade / 100);

            newState.soma = Number.isNaN(soma) ? 0 : soma;

            return newState;
        });
    }

    useEffect(() => {
        !init && onChange && onChange({ position, values });
    }, [values]);

    function handleKeyDown(e) {
        return e.keyCode >= 48 && e.keyCode <= 57;
    }

    const id = uniqueId();

    return (
        <div className="meuselfstorage__tab__content__medidas">
            <fieldset>
                <input
                    min={0}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    ref={alturaRef}
                    type="number"
                    name="altura"
                    id={`altura_${id}`}
                    defaultValue={values.altura}
                />
                <label htmlFor={`altura_${id}`}>Alt.</label>
            </fieldset>
            <span>X</span>
            <fieldset>
                <input
                    min={0}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    ref={larguraRef}
                    type="number"
                    name="largura"
                    id={`largura_${id}`}
                    defaultValue={values.largura}
                />
                <label htmlFor={`largura_${id}`}>Larg.</label>
            </fieldset>
            <span>X</span>
            <fieldset>
                <input
                    min={0}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    ref={profundidadeRef}
                    type="number"
                    name="profundidade"
                    id={`profundidade_${id}`}
                    defaultValue={values.profundidade}
                />
                <label htmlFor={`profundidade_${id}`}>Compr.</label>
            </fieldset>
            <span>=</span>
            <div className="meuselfstorage__tab__content__medida__subtotal">
                <span>{ formatVolume(values.soma) }m³</span>
                <span>Sub-total</span>
            </div>
        </div>
    )
}
