import { createPortal } from "react-dom";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { overlayStates } from "../consts";
import { OverlayItemInput } from "../Components/OverlayItemInput";
import { formatVolume } from "../helpers";
import { useState } from "react";
import { useGlobalStore } from "../Stores/Store";
import {OverlayResume} from "./OverlayResume";
import {OverlayForm} from "./OverlayForm";

export function Overlay() {
    const {
        showOverlay,
        overlayState,
        setOverlayState,
        setShowOverlay,
        selection,
        setSelection,
        totalVolume,
        setShowSuccess,
        medidas,
        hasItems,
    } = useGlobalStore();

    return showOverlay && createPortal(
        (
            <motion.div
                initial={{
                    right: "-1000px",
                }}
                animate={{
                    right: "0",
                }}
                transition={{
                    type: "tween",
                    duration: 1,
                }}
                className="meuselfstorage__calculadora__overlay"
            >
                <div className="meuselfstorage__calculadora__overlay__container">
                    <button
                        onClick={() => setShowOverlay(false)}
                        className="meuselfstorage__calculadora__overlay__container__close"
                    >
                        <FontAwesomeIcon icon={faXmark}/>
                    </button>
                    {overlayState === overlayStates.resume ?
                        <OverlayResume />
                        : null}

                    {overlayState === overlayStates.form
                        ? <OverlayForm />
                        : null}
                </div>
            </motion.div>
        ),
        document.body
    );
}
