import {useRef} from "react";
import {useGlobalStore} from "../Stores/Store";

export function OverlayItemInput({ item }) {
    const { selection, mutateSelection } = useGlobalStore();

    return (<div className="meuselfstorage__calculadora__overlay__container__content__list__item__handle">
        <button onClick={() => mutateSelection(item, true)}>-</button>
        { selection && selection[item.id] ? selection[item.id].qty : 0 }
        <button onClick={() => mutateSelection(item, false)}>+</button>
    </div>);
}